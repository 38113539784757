import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const SurveyHelpManual = () => {
  const headingbanner = {
    title: `Kantime Learning Management System`,
    content: `Home Health and Hospice EMR Solution`,
  };

  const data = {
    images: ["kantime-lms-1.webp", "kantime-lms-2.webp"],
    firstAlt: "kantime-lms",
    firstTitle: "kantime-lms",
    secondAlt: "kantime-lms",
    secondTitle: "kantime-lms",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Saas"],
      },
      {
        title: "What we did",
        text: ["UX Research", "Information Architecture", "UX/UI Design", 'Ui Development'],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Kantime is Home Health and Hospice EMR (Electronic Medical Record) solution provider that works with numerous agencies & their care takers. `,
          `But it faces critical challenges in efficiently managing a diverse array of agencies, each with unique training needs, while also struggling to facilitate the creation and maintenance of customized learning paths. . `,
          `Additionally, there was no way of providing real-time insights into learner progress. . `,
          `A Digital Solution was required to streamline agency management, simplify learning path creation, and progress tracking to ensure agencies can provide top-notch training to the care takers for handling Kantime's Home Health and Hospice EMR softwares.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `To resolve the issue of lack of centralized freight management, a comprehensive digital solution is proposed which is a web based platform, where users can search & generate the quotes for their shipments & can also manage all their shipments at one place. `,
          `We designed the website with a clean, minimalistic aesthetic to reflect the brand's sophistication and to emphasize the trustworthiness. `,
          `The platform also offers various payment modes to help all the users pay for their shipments hassle free. This platform offers a comprehensive suite of solutions that enable businesses to manage the complexities of modern supply chain management effectively.`,
        ],
      },
    ],
    image: ["kantime-lms-problem.webp"],
  };

  const projectData = {
    title: "LMS Manager",
    para: [
      `This is the tool that agency admin have access to. With this tool agencies can organize and monitor employee learning. `,
      `This application is tightly integrated with the another Learning Management System where a learner is primarily logging into.  `,
      `It is primarily user to enroll, manage learners and view their progress, course completion rates and other performance metrics to evaluate the effectiveness of the agency's training programs.`,
      `It is also used for managing learning paths for the agency and assign them to different users.`,
    ],
    para2: [
        `The Learners tab helps agency manager to see the list of all the learners according to their statuses and course details. `,
        `It also shows the progress of each learner in percentage. From this page agency manager can add a new learner by clicking on new enrolment button. `,
        `The agency manager can also unregister a learner or view the assignment status or view learner details through the more action button.`
    ],
    content: {
      image: ["kantime-lms-projectone.webp"],
    },
    content2: {
        image: ["kantime-lms-projectone-2.webp"],
        text: `User Details Page contains all the information of a user along with User ID & status. It also shows how many learning paths a user has enrolled for. Agency manager can also assign a new learning path to a user from this page.`,
    },
  };

  const projectData2 = {
    title: `LMS control Panel`,
    para: [
      `LMS control Panel is the interface designed for the Kantime Admin to create or manage learning paths and assignments as well as registering a new agency & managing its learning path subscriptions. `,
      `In the Assignments tab Kantime admin can create new assignments & add multiple tasks in it for the learners to complete. One learning path contains multiple such assignments. `,
      `Using Agencies tab Kantime admin can manage all the agencies & monitor the performance of each agency individually by going into the agency details page where a mini dashboard shows all the important KOIs related to the agency performance.`,
    ],
    content: {
      image: ["kantime-lms-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Learning paths`,
    para: [
      `From the Learning path's page the Kantime admin can see the corresponding details like total learners & agencies subscribed to a particular learning path. `,
      `Admin can take actions on the learning paths like editing or moving to a different assignment group.`,
      `Admin can also check the performance of a particular learning path by going into its details page. From this page new learning paths can also be added from external database. `,
    ],
    content: {
      image: [
        "kantime-lms-projectthree.webp",
        "kantime-lms-projectthree-2.webp",
        "kantime-lms-projectthree-3.webp",
        "kantime-lms-projectthree-4.webp",
        "kantime-lms-projectthree-5.webp",
        "kantime-lms-projectthree-6.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `We undertook the challenge of revolutionizing Kantime's Agency & Learning Management System (LMS) to better serve administrators and agency managers in the realm of Home Health and Hospice EMR software training. `,
        `Our comprehensive solution introduces intuitive interfaces, streamlined agency management tools, and robust learning path creation features. `,
        `Real-time progress tracking and analytics have been integrated to enhance the overall LMS experience. `,
        `By focusing on usability, accessibility, and continuous improvement through user feedback, our solution empowers Kantime to deliver top-notch training support. `,
        `This transformation ensures that agencies can efficiently harness the power of the LMS, enabling learners to excel in their roles with confidence.`
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={42} />
      </div>
    </Layout>
  );
};

export default SurveyHelpManual;

export const Head = () => (
  <Seo title="Kantime Learning Management System - Octet Design Studio" description="We transformed the home health & hospice EMR software with our services on User Research, UI UX Designing & UI Development, enhancing overall user experience. " />
)